body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: #f2f2f2;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  height: 100%;
}

#react-root {
  display: flex;
  height: 100%;
}

/* Inter */
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter-Regular"),
    url("../../imd-react/assets/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local("Inter-Bold"), url("../../imd-react/assets/fonts/Inter-Bold.ttf") format("truetype");
}

/* Montserrat */
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat-Regular"),
    url("../../imd-react/assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: local("Montserrat-Italic"),
    url("../../imd-react/assets/fonts/Montserrat-Italic.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat-Bold"),
    url("../../imd-react/assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: local("Montserrat-BoldItalic"),
    url("../../imd-react/assets/fonts/Montserrat-BoldItalic.ttf") format("truetype");
}

/* Titillium Web */
@font-face {
  font-display: swap;
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 400;
  src: local("TitilliumWeb-Regular"),
    url("../../imd-react/assets/fonts/TitilliumWeb-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "TitilliumWeb";
  font-style: italic;
  font-weight: 400;
  src: local("TitilliumWeb-Italic"),
    url("../../imd-react/assets/fonts/TitilliumWeb-Italic.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 700;
  src: local("TitilliumWeb-Bold"),
    url("../../imd-react/assets/fonts/TitilliumWeb-Bold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "TitilliumWeb";
  font-style: italic;
  font-weight: 700;
  src: local("TitilliumWeb-BoldItalic"),
    url("../../imd-react/assets/fonts/TitilliumWeb-Bold.ttf") format("truetype");
}
